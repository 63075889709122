<!--
 * @Description: SIM卡详情
 * @Author: ChenXueLin
 * @Date: 2021-10-19 10:53:35
 * @LastEditTime: 2022-08-25 14:26:37
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            btnType="yellowBtn"
            :btnText="`卡状态：${detailForm.status}`"
            :showTime="false"
          >
            <template v-slot:rightButtons>
              <el-button type="primary" @click="editRemark">编辑备注</el-button>
              <el-button type="primary" @click="editInfo">修改卡信息</el-button>
            </template>
          </detail-title-content>
          <!-- 基本信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">基本信息</div>
            <el-form label-width="140px" class="line-form">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="SIM卡号：">
                    {{ detailForm.realNo }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="序列号：">
                    {{ detailForm.serialNo }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="设备三级分类：">
                    {{ detailForm.thirdClassName }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="是否自有卡：">
                    {{ detailForm.isPrivate }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="持有者：">
                    {{ detailForm.haveName }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="持有者类型：">
                    {{ detailForm.haveType }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="供应商：">
                    {{ detailForm.supplierName }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="卡套餐每月流量：">
                    {{ detailForm.monthlyFlow }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="月租费：">
                    {{ detailForm.monthlyFee }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="卡属性：">
                    {{ detailForm.simPropName }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="卡类型：">
                    {{ detailForm.simTypeName }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="是否需要报停：">
                    {{ detailForm.haveToStop }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="流量池编号：">
                    {{ detailForm.flowCellno }}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="创建人：">
                    {{ detailForm.createdBy }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="创建时间：">
                    {{ detailForm.createdTimeString }}
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="备注：">
                    <div class="auto-wrap-content">{{ detailForm.remark }}</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </section>
    </div>
    <!-- 编辑备注弹框 -->
    <el-dialog
      v-dialogDrag
      title="编辑备注"
      :visible.sync="editDialog"
      width="500px"
      :before-close="cancel"
      :close-on-click-modal="false"
      v-loading="editDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-dialog"
    >
      <el-form
        ref="editRemarkForm"
        label-width="88px"
        :model="editRemarkForm"
        :rules="editRemarkFormRules"
      >
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model.trim="editRemarkForm.remark"
            placeholder="备注"
            clearable
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirmEditRemark">确定</el-button>
      </div>
    </el-dialog>
    <!-- 编辑卡信息 -->
    <edit-dialog
      :editVisible="editVisible"
      :selColumnId="[id]"
      :simPackageEnum="simPackageEnum"
      :cardAttributeEnum="cardAttributeEnum"
      :cardTypeEnum="cardTypeEnum"
      :haveToStopEnum="haveToStopEnum"
      @handleClose="handleClose"
      @afterSaveSuccess="queryDetail()"
      type="2"
    ></edit-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import detailTitleContent from "@/components/detailTitleContent";
import {
  simDetail,
  updateRemark,
  findDownList,
  getPackageEnum,
  getPropEnum,
  getTypeEnum
} from "@/api";
import { printError } from "@/utils/util";
import EditDialog from "./editDialog.vue";
export default {
  name: "simDetail",
  components: {
    detailTitleContent,
    EditDialog
  },
  data() {
    return {
      haveToStopEnum: [], //是否需要报停枚举
      simPackageEnum: [], // 卡套餐枚举
      cardAttributeEnum: [], // 卡属性枚举
      cardTypeEnum: [], // 卡类型枚举
      id: "", //当前卡主键id
      detailForm: {
        realNo: "", //sim卡号
        serialNo: "", //序列号
        simPackageId: "", //卡套餐
        isPrivate: "", //是否自有卡
        haveName: "", //持有者
        haveType: "", //持有者类型
        supplierName: "", //供应商
        monthlyFlow: "", //卡套餐每月流量
        monthlyFee: "", //月租费
        flowCellno: "", //流量池编号
        createdBy: "", //创建人
        createdTimeString: "", //创建时间
        remark: "", //备注
        records: [] //记录
      }, //详情

      /****编辑备注*****/
      editDialogLoading: false,
      editDialog: false,
      editRemarkForm: {
        remark: ""
      },
      editRemarkFormRules: {
        remark: [
          {
            required: true,
            message: "请输入备注",
            trigger: ["blur", "change"]
          }
        ]
      },
      /****编辑信息*****/
      editVisible: false
    };
  },
  mixins: [base],
  computed: {},
  watch: {},
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.id = hasParamsRoute.params.id;
    this.id && this.queryDetail(); // 获取初始数据
    this.initData();
  },
  methods: {
    //获取SIM卡详情
    async queryDetail() {
      try {
        let res = await simDetail({ id: this.id });
        this.detailForm = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /******修改备注**********/
    editRemark() {
      this.editDialog = true;
      this.editRemarkForm.remark = this.detailForm.remark;
    },
    // 初始化枚举数据
    async initData() {
      try {
        //获取枚举数据
        let promiseList = [
          findDownList(["haveToStop"]),
          getPackageEnum(),
          getPropEnum(),
          getTypeEnum()
        ];
        let [
          findDownListRes,
          packageEnumRes,
          propEnumRes,
          typeEnumRes
        ] = await Promise.all(promiseList);
        this.haveToStopEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.haveToStop"
        });
        this.simPackageEnum = this.getFreezeResponse(packageEnumRes, {
          path: "data"
        });
        this.cardAttributeEnum = this.getFreezeResponse(propEnumRes, {
          path: "data"
        });
        this.cardTypeEnum = this.getFreezeResponse(typeEnumRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //点击确定
    confirmEditRemark() {
      this.$refs.editRemarkForm.validate(valid => {
        if (valid) {
          this.updateRemark();
        }
      });
    },
    //编辑备注请求
    async updateRemark() {
      try {
        let res = await updateRemark({
          simId: this.id,
          remark: this.editRemarkForm.remark
        });
        if (res.code == "OK") {
          this.$message.success("备注修改成功");
          this.cancel();
          this.queryDetail();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    cancel() {
      this.editRemarkForm = {
        remark: ""
      };
      this.$refs.editRemarkForm.resetFields();
      this.editDialog = false;
    },
    /*******编辑信息************/
    editInfo() {
      this.editVisible = true;
    },
    handleClose() {
      this.editVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.baseInfo-box {
  width: 100%;
  .edit-title {
    width: 100%;
    margin-top: 10px;
  }
  .line-form {
    margin-top: 15px;
    border-bottom: 1px solid #f0f0f0;
  }
  /deep/.el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 15px;
  }
}
.table-wrapper {
  .repair-count {
    font-size: 14px;
    padding: 15px 15px 0px;
    box-sizing: border-box;
    & > :nth-child(1) {
      font-weight: bold;
      margin-right: 5px;
    }
  }
  .elTable {
    margin-top: 15px;
  }
}
/deep/.edit-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 350px;
    }
  }
}
</style>
